import * as React from 'react';
import GlobalStyle from '../globalStyles';

import { Provider } from 'react-redux';
import { store } from '../store';
import { ThemeProvider } from '../styled-components';
import theme from '../theme';
import Helmet from 'react-helmet';
import Footer from '../components/Footer/Footer.component';

export interface LayoutProps {
    location: {
        pathname: string;
        hash: string;
        href: string;
        origin: string;
        protocol: string;
        host: string;
        hostname: string;
    };
    children: any;
}

const Layout = (props: LayoutProps) => {
    return (
        <Provider store={store}>
            <>
                <Helmet
                    titleTemplate="%s | We Make Waves"
                    defaultTitle="Web &amp; Mobile application development | We Make Waves"
                />
                <GlobalStyle />
                <>{props.children}</>

                <Footer />
            </>
        </Provider>
    );
};

const PlainLayout = (props: LayoutProps) => {
    return (
        <Provider store={store}>
            <>
                <Helmet
                    titleTemplate="%s | We Make Waves"
                    defaultTitle="Web &amp; Mobile application development | We Make Waves"
                />
                <GlobalStyle />
                <>{props.children}</>
            </>
        </Provider>
    );
};

export const withLayout = <P extends object>(
    WrappedComponent: React.ComponentType<P>
) =>
    class WithLayout extends React.Component<P & LayoutProps> {
        render() {
            return (
                <ThemeProvider theme={theme}>
                    <Layout location={this.props.location}>
                        <WrappedComponent {...this.props} />
                    </Layout>
                </ThemeProvider>
            );
        }
    };

export const withPlainLayout = <P extends object>(
    WrappedComponent: React.ComponentType<P>
) => {
    // @ts-nocheck
    return class WithLayout extends React.Component<P & LayoutProps> {
        render() {
            return (
                <ThemeProvider theme={theme}>
                    <PlainLayout location={this.props.location}>
                        <WrappedComponent {...this.props} />
                    </PlainLayout>
                </ThemeProvider>
            );
        }
    };
};

export default Layout;
