import * as React from 'react';
import styled from '../../styled-components';
import { pixelToRem, media } from '../../utilities';
import { Constrain } from '../ui';

const BlockSpan = styled.span`
    display: block;

    & > span {
        white-space: nowrap;
    }

    a:not(:first-child) {
        margin-left: 10px;
    }
`;

class Footer extends React.Component<wmw.IBaseStyledComponentProps> {
    public render() {
        const { className } = this.props;

        return (
            <Constrain type="wide">
                <div className={className}>
                    <p>
                        <BlockSpan>We Make Waves</BlockSpan>
                        <BlockSpan>WeWork, 41 Corsham Street</BlockSpan>
                        <BlockSpan>London, N1 6DR</BlockSpan>
                    </p>
                    <p>
                        <BlockSpan>
                            {new Date().getFullYear()} &copy; We Make Waves® All
                            rights reserved.
                        </BlockSpan>
                        <BlockSpan>
                            <span>Registered company: 04023284.</span>{' '}
                            <span>VAT no: 766525795.</span>
                        </BlockSpan>
                        <BlockSpan>
                            <a href="/code-of-ethics/">Code of ethics</a>
                            <a href="/cookies/">Cookies</a>
                            <a href="/privacy/">Privacy statement</a>
                        </BlockSpan>
                    </p>
                </div>
            </Constrain>
        );
    }
}

export default styled(Footer)`
    padding: 3rem 0;
    font-size: ${({ theme }) => theme.fontNew.primary.size.level7.size};

    ${media.xl`
          font-size: ${({ theme }) => theme.fontNew.primary.size.level6.size};
    `}

    p:last-child {
        margin: 0;
    }

    ${media.m`
        padding: ${pixelToRem(20)} 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        p {
            max-width: 50%;
            margin: 0;

            &:nth-child(2) {
                text-align: right;
            }
        }
    `};

    a {
        color: ${({ theme }) => theme.colors.deepBlue.color};
    }

    ${media.xl`
        padding-left: 3rem;
        padding-right: 3rem;
    `}
`;
