import React from 'react';
import { Normalize } from 'styled-normalize';
import theme from './theme';

import GuthenBloots from './fonts/Guthen Bloots Basic.otf';
import { media } from './utilities';
import { createGlobalStyle } from './styled-components';

const BodyStyles = createGlobalStyle`
    @font-face {
        font-family: 'Guthen Bloots';
        src: url("${GuthenBloots}") format("opentype");
        font-style: normal;
        font-weight: 400;
        font-display: fallback;
    }

    html,
    body {
        /* for the individual article pages (cross-browser) */
        overflow-x: hidden !important;
        position: relative;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 10px;
        height: auto !important;
    }

    html {
        box-sizing: border-box;

    }
    *, *:before, *:after {
        box-sizing: inherit;
    }

    body {
        background: #fff;
        font-family: ${theme.fontNew.primary.type};
        line-height: 1.6;
        color: ${theme.colors.deepBlue.color};
        font-size: ${theme.fontNew.primary.size.level6.size};

        ${media.xl`
            font-size: ${theme.fontNew.primary.size.level5.size};
        `}
    }

    img, video {
        width: 100%;
    }
    
    strong {
        font-weight: 500;
    }

    a {
        text-underline-position: from-font;
    }
    
    /* 
        This is to provide a bit of additional space on headers that are used as internal anchors so that our fixed header doesn't over lap them
        Its not perfect in that Chrome seems to also include margins (which are variable based on viewport and heading size), whereas webkit doesn't.
        Ideally we'd use https://caniuse.com/mdn-css_properties_scroll-margin-top 
        But even safaris partial support, using  a different name, doesn't support linking to fragments.
    */
    h1, h2, h3, h4, h5, h6 {
        &[id]::before {
            content: '';
            display: block;
            height: 70px;
            margin-top: -70px;
            visibility: hidden;

            ${media.l`
                height: 100px;
                margin-top: -100px;
            `}
        }
    }

`;

const ResetStyles = createGlobalStyle`
    h1, h2, h3, h4, h5, h6, ul, ol, blockquote, p {
        margin-top: 0;
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: 500;
    }
`;

export default () => (
    <>
        <Normalize />
        <ResetStyles />
        <BodyStyles />
    </>
);
